import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  createScenarioFlag = new BehaviorSubject(false);

  constructor() {}

  /**
   * function is used get access token
   */
  get accessToken(): string {
    return localStorage.getItem('predealtoken');
  }

  /*
   * function is used for create scenario flag manage
   */
  createScenarioState(state: boolean) {
    this.createScenarioFlag.next(state);
  }

  public getSelectedIds(data: any, idKey: string = 'id'): string[] | null {
    const newData = data.map((item) => String(item[idKey]));
    return newData.length ? newData : null;
  }
}
